.mainSection {
    position: absolute;
    top: 90%;
    left: 10%;
    margin: auto;
    width: 80%;
 }

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f4f4f4;
  }
  .section1 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
  }
  .section1Cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .section1Cont > div:first-child {
    flex: 1;
  }
  .section1Cont h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }
  .section1Cont h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #222;
  }
  .section1Cont h3 {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #555;
  }
  .secondImg {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .secondImg img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

.section1Cont a{
    text-decoration: underline;
}

@media (max-width: 768px) {
    .section1Cont {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .textContent, .secondImg {
      padding: 10px;
    }
  
    .textContent h2, .textContent h1, .textContent h3 {
      text-align: center;
    }
  
    .secondImg {
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .textContent h2 {
      font-size: 1.5em;
    }
  
    .textContent h1 {
      font-size: 2em;
    }
  
    .textContent h3 {
      font-size: 1.2em;
    }
  }