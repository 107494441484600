.socialmediaContainer {     
    width: 80%;
    margin: auto;
    margin-top: 100px;
    justify-content: center;
 }

.socialmediaContainer h1{
    font-size: 60px;
    margin-top: 50px;
}

.socialmediaContainer h2{
    font-size: 60px;
}