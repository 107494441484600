* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}  
a {
    text-decoration: none;
}
li {
    list-style: none;
}
/* NAVBAR STYLING STARTS */
.navbar {
    display: flex;    
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    width: 100%;
    /* height: 70px; */
    /* height: 40px; */
  }

.navName h2{
    cursor: pointer;
    -webkit-user-select:none ;
    -webkit-user-drag: none;
    font-size: 10px;
    font-weight: 700;
    justify-content: center;
    align-items: center;    
    color: #494949;
    font-family: 'Courier New', Courier, monospace;
}

 headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 1;
 } 
.nav-links a {
    color: #494949;
    font-size: 20px;
    text-decoration: none;
    transition:.3s ease all;
    /* font-weight: bold; */
    font-family: Arial, Helvetica, sans-serif;
    /* font-family: 'Courier New', Courier, monospace; */
}
 

/* LOGO */
.logoHolder {
    margin: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
 }

.logoHolder h1{
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
    align-items: center;    
    color: #494949;
    font-family: 'Courier New', Courier, monospace;
 }

 

/* NAVBAR MENU */
.menu {
    display: flex;
    gap: 1em;
    font-size: 18px;
}

/* .menu li:hover {
    background-color: #00b613;
    transition: all 0.5s ease-in-out;
    transition: 0.3s ease;
    padding: 5px;
} */

.navName:hover{
    background-color: #494949;
    transition: all 0.5s ease-in-out;
    transition: 0.3s ease;
    padding: 5px;
    color: #f5f5f5;
}

.menu li {
    padding: 5px 14px;
}

/* DROPDOWN MENU */
.services {
    position: relative;
}

.dropdown {
    background-color: #ffffff;
    padding: 1em 0;
    position: absolute;
    /*WITH RESPECT TO PARENT*/
    display: none;
     top: 35px;
     z-index: 1;
     width: 1500px;
     height: 400px;
     right: 10%;
      
}

.dropdown li+li {
    margin-top: 10px;
}

.dropdown li {
    width: 15em;
    text-align: center;
    margin-top: 20px;
    text-align: left;
}

.dropdown li:hover {
    background-color: #f5f5f5;
}

.services:hover .dropdown {
    display: block;
}

.dropdown h2{
    font-size: 40px;
}

.dropdownContainer {
     display: flex;
 }

.dropdownContent {
     margin: 20px;
}

 

/*RESPONSIVE NAVBAR MENU STARTS*/
/* CHECKBOX HACK */
input[type=checkbox] {
    display: none;
}

/*HAMBURGER MENU*/
.hamburger {
    display: none;
    font-size: 24px;
    color: rgb(0, 0, 0);
    -webkit-user-select: none;
}

/* APPLYING MEDIA QUERIES */
@media (max-width: 768px) {
    .menu {
        display: none;
        position: absolute;
        background-color: #f5f5f5;
        right: 0;
        left: 0;
        text-align: center;
        padding: 16px 0;
    }

    .menu li:hover {
        display: inline-block;
        background-color: #c5c5c5;
        transition: 0.3s ease;
        color: #f5f5f5;
    }

    .menu li+li {
        margin-top: 12px;
    }

    input[type=checkbox]:checked~.menu {
        display: block;
    }

    .hamburger {
        display: block;
    }

    /* .dropdown {
        left: 50%;
        top: 30px;
        transform: translateX(35%);
    } */

    .dropdown li:hover {
        background-color: #494949;
    }
    .nav-links .menu .dropdown {
        flex-direction: column;
        position: relative;
        top: 0;
        left: 0;
      }
}

#active1 a {
    background-color: #494949;
     padding: 5px;
    color: '#f5f5f5';
 }

 .logbutton{             
     border: 1px solid #494949;
     margin: none;
     padding: none;
   }

    .logbutton:hover{
        background-color: #c5c5c5;
        color: #ffffff;
    }

   
    .Signup:hover{
        background-color: #f5f5f5;
        /* background-color: #ff8e8e; */
        transition: all 0.5s ease-in-out;
        transition: 0.3s ease;
        padding: 5px;
    }

    .Signup a:hover{
        color: #494949;
    }

    .Signup a{
        color: #fff;
     }

/* sereach bar */
.searchBar {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;    
}

/* input{
    width: 700px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    padding: 0 10px;
} */

#profile{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
}

 
/* #profileNav:hover{
     width: 300px;      
  } */

 
.logOut{
     align-items: center;
    cursor: pointer;
} 

nav h3{
     font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
    font-family: 'Courier New', Courier, monospace;

}

.NavCartIcon{
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #494949;

}
.NavCartIcon:hover{
    color: #494949;
    background-color: #fff;
    transform: scale(1.1);

}

.NavCartIcont{
    display: flex;
    position: relative;
  }

.NavCartIcont p{
    position: absolute;
    top: -0.1rem;
    right: -1rem;
    text-align: center;
    font-weight: 500;
    background-color: #494949;
    color: #f5f5f5;
    border-radius: 10px;
    width: 20px;
}
 
.profileIconInfo {
    display: flex;
 }

.profileIconInfo h1{
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
    font-family: 'Courier New', Courier, monospace;

}