.homeImg {
    margin-top: 60px;
    position: absolute;
}

.texth1{
    position: absolute;
    top: 20%;
    left: 10%;
    font-size: 60px;
    color: #f5f5f5;
}

.texth2 {
    position: absolute;
    top: 35%;
    left: 10%;
    font-size: 40px;
    color: #f5f5f5;
    margin-right: 50px;
    width: 500px;
}

.texth3 h2 {
    position: absolute;
    top: 65%;
    left: 10%;
    font-size: 30px;
    color: #494949;
    margin-right: 50px;
    background-color: #f5f5f5;
    padding: 10px;
    align-items: center;
    /* border-radius: 20px; */
    border: none;
    /* font-family: 'Courier New', Courier, monospace; */
    /* font-family: Arial, Helvetica, sans-serif; */
}
 

.texth3 h2:hover{
    background-color: #494949;
    cursor: pointer;
    color: #f5f5f5;
    transition: all 0.5s ease-in-out;
    transition: 0.3s ease;
 }
 