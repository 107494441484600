/* UploadImage.css */
.profile {
    margin-top: 200px;
    margin-bottom: 100px;
}
.uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 700px;
    height: 500px;
    margin: 0 auto;
  }
  
  .uploadImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .uploadThum {
    width: 300px;
    height: 200px;
    /* border-radius: 50%; */
    cursor: pointer;
    margin-bottom: 20px;
    object-fit: cover;
    border: 2px dashed #ccc;
    transition: border-color 0.3s;
  }
  
  .uploadThum:hover {
    border-color: #777;
  }
  
  img[alt="Uploaded Image"] {
    width: 400px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .titleContainer {
    width: 50%;
    margin-bottom: 10px;
   }
  
  .imageTitle {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
    margin-top: 10px;
    
  }
  
  .imageTitle:focus {
    border-color: #777;
  }
  
  .description {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    resize: none;
    transition: border-color 0.3s;
  }
  
  .description:focus {
    border-color: #777;
  }
  
  .uploadImageButton {
    background-color: #494949;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-bottom: 20px;
  }
  
  .uploadImageButton:hover {
    background-color: #292a2a;
  }
  
  .uploadImageButton h2 {
    margin: 0;
  }
  
  p {
    margin: 10px 0;
    font-size: 14px;
    color: #333;
  }
  