.artprofContainer {     
    width: 80%;
    margin: auto;
    margin-top: 100px;
    justify-content: center;
 }

.artprofContainer h1{
    font-size: 60px;
    margin-top: 50px;
}

.artprofContainer h2{
    font-size: 60px;
}

#sectionID{
    margin-bottom: 50px;
 }