.postContainer {
    width: 100%;
    margin: auto;
    margin-top: 70px;
    height: 800px;
   overflow-y: auto;
    }
  .addPost_container {
     width: 100%;
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     /* background-color: #e91f1f; */
     /* box-shadow: 0 0 10px 0 #f5f5f5; */
  
  }
  
  .image{
     align-items: center;
    text-align: center;
  }
  
  .addPost h1{
     margin-bottom: 10px;
     font-size: 30px;
     font-weight: 700;
     justify-content: center;
     align-items: center;    
     color: #494949;
     font-family: 'Courier New', Courier, monospace;
   }
  
  .addPost h2{
     font-size: 20px;
     text-align: center;
     justify-content: center;
     align-items: center;    
     color: #494949;
     font-family: 'Courier New', Courier, monospace;
   }
  
   .artBox{
     display: flex;
     /* flex-wrap: wrap; */
     width: 45%;
     height: 600px;
     background-color: #f5f5f5;
     margin: 30px;
    }
  
   .artBox img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     -webkit-backface-visibility: hidden;
  
      }
  
   .artBox img:hover{    
     opacity: 0.5;
     cursor: pointer;
   }
  
   .artInputs {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 0;
     text-align: center;
     height: 650px;
     width: 45%;
     margin: 30px;
     margin: 30px;
     border: 1px solid #494949;
       /* overflow: scroll; */
    }

    .shippingContInput {
        width: 400px;
        height: 40px;
        margin-top: 10px;
    }
  
    /* .addpostForm {
     overflow: scroll;
    } */
  
   .input{
     width: 400px;
     height: 40px;
     margin: 10px;
     padding: 10px;
     font-size: 16px;
     font-weight: 400;
     color: #494949;
     background-color: #f5f5f5;
     font-family: 'Courier New', Courier, monospace;
   }
  
    
  
   .artInputs h1{
     text-decoration: none;
     color: #f5f5f5;
     font-size: 20px;
     font-weight: 700;
     font-family: 'Courier New', Courier, monospace;
     background-color: #494949;
     padding: 10px;
     margin: 10px;
     cursor: pointer;
   }
  
   .sumbit h1:hover{
     background-color: #ffffff;
     color: #494949;
     /* border: 1px solid #494949;;         */
    }
  
    .test{
     margin-top: 200px;
    }
  
    .custom-file-upload {
       opacity: 100;
       width: 500px;
       cursor: pointer;
    }
  
   .all{
     margin-top: 80px;
     background-color: antiquewhite;
   }
  
   .title{
     width: 400px;
     height: 40px;
     margin: 10px;
     padding: 24px;
     font-size: 16px;
     font-weight: 400;
     color: #494949;
     background-color: #f5f5f5;
     font-family: 'Courier New', Courier, monospace;
   }
  
  
   .AddPostInpCon {.postContainer {
    width: 100%;
    margin: auto;
    margin-top: 70px;
    height: 800px;
   overflow-y: scroll;
  }
  .addPost_container {
     width: 100%;
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     /* background-color: #e91f1f; */
     /* box-shadow: 0 0 10px 0 #f5f5f5; */
  
  }
  
  .addPost h1{
     margin-bottom: 10px;
     font-size: 30px;
     font-weight: 700;
     justify-content: center;
     align-items: center;    
     color: #494949;
     font-family: 'Courier New', Courier, monospace;
   }
  
  .addPost h2{
     font-size: 20px;
     text-align: center;
     justify-content: center;
     align-items: center;    
     color: #494949;
     font-family: 'Courier New', Courier, monospace;
   }
  
   .artBox{
     display: flex;
     /* flex-wrap: wrap; */
     width: 45%;
     height: 600px;
     background-color: #f5f5f5;
     margin: 30px;
    }
  
   .artBox img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     -webkit-backface-visibility: hidden;
  
      }
  
   .artBox img:hover{    
     opacity: 0.5;
     cursor: pointer;
   }
  
   .artInputs {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 0;
     text-align: center;
     height: 600px;
     width: 45%;
     margin: 30px;
     background-color: #ffffff;
     margin: 30px;
     box-shadow: 0 0 10px 0 #00000025;
     /* overflow: scroll; */
    }
  
    /* .addpostForm {
     overflow: scroll;
    } */
  
   .input{
     width: 400px;
     height: 40px;
     margin: 10px;
     padding: 10px;
     font-size: 16px;
     font-weight: 400;
     color: #494949;
     background-color: #f5f5f5;
     font-family: 'Courier New', Courier, monospace;
   }
  
    
  
   .artInputs h1{
     text-decoration: none;
     color: #f5f5f5;
     font-size: 20px;
     font-weight: 700;
     font-family: 'Courier New', Courier, monospace;
     background-color: #494949;
     padding: 10px;
     margin: 10px;
     cursor: pointer;
   }
  
   .sumbit h1:hover{
     background-color: #ffffff;
     color: #494949;
     /* border: 1px solid #494949;;         */
    }
  
    .test{
     margin-top: 200px;
    }
  
    .custom-file-upload {
       opacity: 100;
       width: 500px;
       cursor: pointer;
    }
  
   .all{
     margin-top: 80px;
     background-color: rgb(255, 255, 255);
   }
  
   .title{
     width: 400px;
     height: 40px;
     margin: 10px;
     padding: 24px;
     font-size: 16px;
     font-weight: 400;
     color: #494949;
     background-color: #f5f5f5;
     font-family: 'Courier New', Courier, monospace;
   }
  
  
   .AddPostInpCon {
     display: flex;
   }
  
  .AddPostInpCon option{
   font-family: 'Courier New', Courier, monospace;
   font-size: 17px;
   color: #494949;
   cursor: pointer;
   transition: 0.5s;
  }
  
   
  
  .AddPostInpCon select{
   font-family: 'Courier New', Courier, monospace;
   font-size: 17px;
   color: #494949;
   cursor: pointer;
   transition: 0.5s;
  }
  
  .desc{
   width: 800px;
   height: 100px;
  }
     display: flex;
   }
  
  .AddPostInpCon option{
   font-family: 'Courier New', Courier, monospace;
   font-size: 17px;
   color: #494949;
   cursor: pointer;
   transition: 0.5s;
  }
  
  .AddPostInpCon select{
   font-family: 'Courier New', Courier, monospace;
   font-size: 17px;
   color: #494949;
   cursor: pointer;
   transition: 0.5s;
  }
  
  .desc{
   width: 800px;
   height: 100px;
  }
  
  @media screen and (max-width: 576px) {
    /* Example: Hide the image preview container on small screens */
    .postContainer {
       width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      display: block;
    }
  }
  
  @media screen and (max-width: 600px) {
     .AddPostInpCon{
      background-color: #ffffff;
      width: auto;
       flex-wrap: wrap;
       display: inline !important ;
       overflow: auto;
         }
  }
  
  @media screen and (max-width: 576px) {
    .artInputs{
      width: auto;
         }
  }
  
   
  .addArtworkTextH1 h1{
    font-size: 60px;
    margin-top: 50px;
    text-align: center;
}