.galleryContainer {     
    width: 80%;
    margin: auto;
    margin-top: 100px;
    justify-content: center;
 }

.galleryContainer h1{
    font-size: 60px;
}

.gallerySection2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 50px;
  background-color: #f4f4f4;
}
 

.galleriesGrid img{
    width: 350px;
    height: 200px;
}